<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <span
        class="font-weight-bold text-body-2"
      >9 Описание товаров</span>
    </v-col>
    <v-col
      cols="5"
    >
      <label>
        Тип транспортного средства
        <field-menu>
          <v-list-item
            v-if="!customFieldCheckerNull(transport, item.vehicle_type_name, 'name')"
            @click="item.vehicle_type_name = '' "
          >
            Произвольное значение
          </v-list-item>
          <v-list-item
            v-else
            @click="item.vehicle_type_name = null"
          >
            Значение из справочника
          </v-list-item>
        </field-menu>
      </label>
      <div class="d-flex">
        <v-text-field
          v-show="customFieldCheckerNull(transport, item.vehicle_type_name, 'name')"
          ref="vehicle_type_name"
          v-model="item.vehicle_type_name"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onVehicleTypeNameChange"
        />
        <v-autocomplete
          v-show="!customFieldCheckerNull(transport, item.vehicle_type_name, 'name')"
          ref="vehicle_type_name"
          v-model="item.vehicle_type_name"
          auto-select-first
          :items="transport"
          outlined
          hide-details="auto"
          dense
          background-color="white"
          color="#5CB7B1"
          item-color="#5CB7B1"
          class="pb-2"
          item-text="name"
          item-value="name"
          :filter="filterBySearchField"
          @change="onVehicleTypeNameChange"
        />
      </div>
    </v-col>
    <v-col
      cols="1"
      align-self="center"
    >
      <v-checkbox
        v-model="item.six"
        :disabled="!isEcologyAvailable"
        color="teal"
        label="ЕВРО-6"
        hide-details
        @change="onEcologyClassChange"
      />
    </v-col>
    <v-col
      cols="3"
    >
      <label>
        Марка
        <field-menu>
          <v-list-item
            v-if="!customFieldCheckerNull(nsiTransportMarks, item.mark_name, 'name')"
            @click="item.mark_name = '' "
          >
            Произвольное значение
          </v-list-item>
          <v-list-item
            v-else
            @click="item.mark_name = null"
          >
            Значение из справочника
          </v-list-item>
        </field-menu>
      </label>
      <div class="d-flex">
        <v-text-field
          v-show="customFieldCheckerNull(nsiTransportMarks, item.mark_name, 'name')"
          ref="mark_name"
          v-model="item.mark_name"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onMarkChange"
        />
        <v-autocomplete
          v-show="!customFieldCheckerNull(nsiTransportMarks, item.mark_name, 'name')"
          ref="mark_name"
          v-model="item.mark_name"
          auto-select-first
          :items="nsiTransportMarks"
          item-text="name"
          item-value="name"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
          @change="onMarkChange"
        />
      </div>
    </v-col>
    <v-col
      cols="3"
    >
      <label>
        Модель
      </label>
      <div class="d-flex">
        <v-combobox
          ref="model_name"
          :value="item.model_name"
          :items="filteredModels"
          auto-select-first
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @update:search-input="selectModel"
          @change="rewriteTitle(item)"
        />
      </div>
    </v-col>
    <v-col
      cols="2"
    >
      <label>
        Идентификац. номер (VIN)
      </label>
      <div class="d-flex">
        <v-text-field
          ref="vin"
          v-model="item.vin"
          v-mask="customTokens"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="rewriteTitle(item)"
        />
      </div>
    </v-col>
    <v-col>
      <label>
        Тип двигателя
      </label>
      <div class="d-flex">
        <v-autocomplete
          ref="engine_type"
          v-model="item.engine_type"
          auto-select-first
          item-text="name"
          item-value="value"
          :items="engineTypes"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="rewriteTitle(item)"
        />
      </div>
    </v-col>
    <v-col
      cols="2"
    >
      <label>
        Объем двигателя
      </label>
      <div class="d-flex">
        <v-text-field
          ref="volume"
          :disabled="disabledVolume"
          :value="item.volume"
          class="mr-2"
          outlined
          dense
          hide-details="auto"
          :background-color=" disabledVolume ? 'grey lighten-2': 'white'"
          @input="(v) => (item.volume = v.replace(',', '.'))"
          @change="(value) => [replaceToInt({value, prop:'volume'}), rewriteTitle(item)]"
        />
        <v-autocomplete
          ref="volume_measure_unit_letter"
          v-model="item.volume_measure_unit_digit"
          :disabled="disabledVolume"
          :background-color=" disabledVolume ? 'grey lighten-2': 'white'"
          auto-select-first
          item-text="text"
          item-value="code"
          :items="volumeMeasureUnits"
          outlined
          dense
          hide-details="auto"
          :filter="filterBySearchField"
          @change="rewriteTitle(item)"
        />
      </div>
    </v-col>
    <v-col
      cols="2"
    >
      <label>
        Полная масса
      </label>
      <div class="d-flex">
        <v-text-field
          ref="weight"
          :value="item.weight"
          class="mr-2"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(v) => (item.weight = v.replace(',', '.'))"
          @change="(value) => [replaceFloatNumbers({value, prop:'weight'}), rewriteTitle(item)]"
        />
        <v-autocomplete
          ref="weight_measure_unit_letter"
          v-model="item.weight_measure_unit_digit"
          auto-select-first
          item-text="text"
          item-value="code"
          :items="weightMeasureUnits"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :filter="filterBySearchField"
          @change="rewriteTitle(item)"
        />
      </div>
    </v-col>
    <v-col
      cols="2"
    >
      <label>
        Мощность
      </label>
      <div class="d-flex">
        <v-text-field
          ref="power"
          :value="item.power"
          class="mr-2"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(v) => (item.power = v.replace(',', '.'))"
          @change="(value) => [replaceFloatNumbers({value, prop:'power'}), rewriteTitle(item)]"
        />
        <v-autocomplete
          ref="power_measure_unit_letter"
          v-model="item.power_measure_unit_digit"
          auto-select-first
          item-text="text"
          item-value="code"
          :items="powerMeasureUnits"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :filter="filterBySearchField"
          @change="rewriteTitle(item)"
        />
      </div>
    </v-col>
    <v-col
      cols="2"
    >
      <label>
        Дата выпуска
        <v-tooltip
          max-width="300"
          top
        >
          <template #activator="{ on, attrs }">
            <v-icon
              tabindex="-1"
              class="mx-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >mdi-information
            </v-icon>
          </template>
          <span>
            <ul class="pl-2">
              <li>Если нет информации о дате выпуска, необходимо ввести <strong>15 число</strong>;</li>
              <li>Если нет информации о дате и месяце выпуска, необходимо ввести <strong>1 июля</strong>;</li>
            </ul>
          </span>
        </v-tooltip>
      </label>
      <div>
        <custom-date-picker
          ref="release_date"
          v-model="item.release_date"
          :visited.sync="visited"
          :has-changes.sync="hasChanges"
          input-class="release_date"
          dense
          @change="releaseChanged($event)"
          @handleChange="releaseChanged($event)"
        />
      </div>
    </v-col>
    <v-col cols="12">
      <v-textarea
        ref="description"
        v-model="item.title"
        outlined
        auto-grow
        dense
        row-height="30"
        hide-details="auto"
        background-color="white"
        rows="2"
      />
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import cloneDeep from 'lodash.clonedeep'
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import {getMeasureUnitShortName, getTransportCodeByName} from '@/helpers/catalogs'
import {filterBySearchFieldMixin} from "@/mixins/catalogs"
import {convertEmptyStringsToNull} from '@/helpers/objects'
import formatDate from '@/mixins/format-date.js'
import customFieldChecker from '@/mixins/custom-field-checker'
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import {tnvedCodeChanged} from "@/events/statistics";
import FieldMenu from "@/components/documents/field-menu.vue";
import {readyToUpdateInjector} from "@/helpers/injectors";

const N3_TRANSPORT_CATEGORY = "N3"
const TRACTOR_UNIT = "СЕДЕЛЬНЫЙ ТЯГАЧ"
const TONNES = "Т"
const CM3 = "СМ3, МЛ"
const KW = "КВт"
const HP = "Л.С."
export default {
  components: {FieldMenu, CustomDatePicker},
  mixins: [filterBySearchFieldMixin, formatDate, customFieldChecker, HighlightFieldWareSector],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    wareDetails: {
      required: true,
      type: Array,
    },
    showQuantity: {
      required: true,
      type: Boolean,
    },
    info: {
      type: Object,
      required: true
    },
    nsiTransportMarks: {
      required: true,
      type: Array,
    }
  },
  data: () => ({
    models: [],
    customTokens: {
      mask: 'CCCCCCCCCCCCCCCCC',
      tokens: {
        'C': {
          pattern: /(?![QIOqio])[0-9a-zA-Z]/,
          transform: function (v) {
            return v.toLocaleUpperCase();
          }
        }
      }
    },
    item: {
      id: "",
      declaration_id: "",
      title: "",
      vehicle_type_name: "",
      vehicle_type_code: "",
      mark_name: "",
      model_name: "",
      vin: "",
      volume: "",
      weight: "",
      power: "",
      release_date: "",
      volume_measure_unit_digit: "",
      volume_measure_unit_letter: "",
      weight_measure_unit_digit: "",
      weight_measure_unit_letter: "",
      power_measure_unit_digit: "",
      power_measure_unit_letter: "",
      engine_type: "",
      six: null
    },
    engineTypes: [
      {
        name: 'бензин (в т.ч. гибрид)',
        value: 'бензин',
      },
      {
        name: 'дизель (в т.ч. гибрид)',
        value: 'дизель',
      },
      {
        name: 'электромобиль',
        value: 'электромобиль',
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
      selected_wares: "rs/getSelectedWares",
      measure_units: "catalogs/getNsiMeasureUnits",
      transport: "catalogs/getNsiTransportOtherCategories",
      user: "auth/getUser",
    }),
    filteredModels() {
      const modelName = this.item.model_name?.toLowerCase()
      return modelName ? this.models.filter(model => model.toLowerCase().startsWith(modelName)) : this.models
    },
    disabledVolume() {
      // return this.info.tn_ved?.startsWith('870380')
      return false
    },
    isEcologyAvailable(){
      return this.info.transport_category === N3_TRANSPORT_CATEGORY && this.item.vehicle_type_name === TRACTOR_UNIT
    },
    weightMeasureUnits(){
      return this.measure_units.filter(measure => measure.shortDescription === TONNES)
    },
    volumeMeasureUnits(){
      return this.measure_units.filter(measure => measure.shortDescription === CM3)
    },
    powerMeasureUnits(){
      return this.measure_units.filter(measure => [KW, HP].includes(measure.shortDescription))
    },
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
        this.searchModels()
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onCodeChange)
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe()
  },
  methods: {
    onVehicleTypeNameChange(){
      if(!this.isEcologyAvailable) this.clearEcologyClass();
      this.rewriteTitle(this.item)
    },
    clearEcologyClass(){
      this.item.six = null
    },
    onMarkChange() {
      this.searchModels()
      this.rewriteTitle(this.item)
    },
    selectModel(value) {
      this.item.model_name = value
      this.rewriteTitle(this.item)
    },
    searchModels() {
      this.$store.dispatch('catalogs/searchModels', {
        markName: this.item.mark_name,
        divisionId: this.user.user.activeDivisionId
      }).then(res => {
        this.models = res.data.map(model => model.model_name)
      })
    },
    onCodeChange({id, code}) {
      if (id === this.item.id && code.startsWith('870380')) {
        this.clearVolume()
        this.rewriteTitle(this.item)
      }
    },
    clearVolume() {
      this.item.volume = null
      this.item.volume_measure_unit_digit = null
      this.item.volume_measure_unit_letter = null
    },
    replaceToInt({value, prop}) {
      if (value.includes('.')) { //Если не целое число
        const int = Number.parseInt(value)
        return this.item[prop] = int
      }
      return this.item[prop] = value
    },
    replaceFloatNumbers({value, prop}) {
      if (value.includes('.')) { //Если не целое число
        let result = ""
        const float = Number.parseFloat(value)
        if (value >= 0.001) {
          result = float.toFixed(3).replace(/0*$/, "").replace(/\.*$/, "") // Убираем лишние нули в конце и если нужно точку
        } else {
          result = float.toFixed(6).replace(/0*$/, "").replace(/\.*$/, "")
        }
        return this.item[prop] = result
      }
      return this.item[prop] = value
    },
    rewriteTitle(data) {
      const new_title = {
        car: [data.vehicle_type_name, data.mark_name, data.model_name].filter((el) => el !== null && el !== '').join(' '),
        vin: data.vin ? `VIN: ${data.vin}` : null,
        volume: data.volume ? `Объем двигателя${data.engine_type ? ` (${data.engine_type})` : ''}: ${data.volume} ${getMeasureUnitShortName(this.measure_units, data.volume_measure_unit_digit) || ''}` : null,
        weight: data.weight ? `Полная масса: ${data.weight} ${getMeasureUnitShortName(this.measure_units, data.weight_measure_unit_digit) || ''}` : null,
        power: data.power ? `Мощность: ${data.power} ${getMeasureUnitShortName(this.measure_units, data.power_measure_unit_digit) || ''}` : null,
        year: data.release_date ? `${this.formatReleaseYear(data.release_date)} года выпуска` : null
      }
      const payload = convertEmptyStringsToNull(new_title)
      const new_title_arr = []
      Object.keys(payload).forEach(item => {
        if (payload[item]) {
          new_title_arr.push(payload[item])
        }
      })
      this.item.title = new_title_arr.join('; ')
    },
    releaseChanged() {
      this.$emit('releaseChanged')
      this.$nextTick(() => {
        this.rewriteTitle(this.item)
      })
    },
    setFields() {
      const ware = cloneDeep(this.selected_wares[this.index])
      Object.keys(this.item).forEach(key => {
        if (key in ware) {
          this.item[key] = ware[key]
        }
      })
    },
    onEcologyClassChange() {
      this.readyToUpdate()
    },
    updateEditableGoodsItem() {
      const payload = {
        ...this.item,
        volume_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.volume_measure_unit_digit),
        weight_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.weight_measure_unit_digit),
        power_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.power_measure_unit_digit),
        vehicle_type_code: getTransportCodeByName(this.transport, this.item.vehicle_type_name),
      }
      this.$emit('update:info', payload)
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
  },
}
</script>

<style scoped>
th {
  font-size: 10px !important;
}

td {
  font-size: 12px !important;
}

.v-divider {
  border-style: dashed;
}
</style>
